/*!

=========================================================
* Black Dashboard React v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import Home from "pages/home";
import HotelList from "pages/hotelList";
import HotelData from "pages/hotelData";
import Reports from "pages/reports";
import Notification from "pages/notification";
import Contact from "pages/contact";
import Profile from "pages/profile";

import {
  FaChartLine,
  FaRegBuilding,
  FaRegFileAlt,
  FaRegBell,
  FaRegPaperPlane
} from "react-icons/fa";

var routes = [
  {
    path: "/dashboard",
    name: "Rendimentos",
    icon: <FaChartLine size={20} style={{ marginRight: 10 }} />,
    component: Home,
    layout: "/admin"
  },
  {
    path: "/hotel/list",
    name: "Hotéis",
    icon: <FaRegBuilding size={20} style={{ marginRight: 10 }} />,
    component: HotelList,
    layout: "/admin"
  },
  {
    path: "/hotel/:id",
    component: HotelData,
    layout: "/admin"
  },
  {
    layout: "/admin",
    redirect: true,
    from: "/admin/hotel/",
    to: "/admin/hotel/list"
  },
  {
    path: "/reports",
    name: "Relatórios",
    icon: <FaRegFileAlt size={20} style={{ marginRight: 10 }} />,
    component: Reports,
    layout: "/admin"
  },
  {
    path: "/notification",
    name: "Notificação",
    icon: <FaRegBell size={20} style={{ marginRight: 10 }} />,
    component: Notification,
    layout: "/admin"
  },
  {
    path: "/contact",
    name: "Contato",
    icon: <FaRegPaperPlane size={20} style={{ marginRight: 10 }} />,
    component: Contact,
    layout: "/admin"
  },
  {
    path: "/profile",
    component: Profile,
    layout: "/admin"
  }  
];
export default routes;
