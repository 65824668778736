import React, { Component } from "react";
import {
  Row,
  Col,
  CardHeader,
  CardTitle
} from "reactstrap";

import CardUnit from "./CardUnit";
import CardExpenses from "./CardExpenses";
import CardIncome from "./CardIncome";
import CardTable from "./CardTable";


import { periods as periodNameList } from "data";
import { connect } from "react-redux";

import PeriodInfo from "./PeriodInfo";
import UnitInfo from "./UnitInfo";
import CardChart from "./CardChart";

import Api from "api";

import { showMessage } from "../../actions";

class Home extends Component {
  state = {
    VALORBRUTO: 0,
    VALORFUNDORESERVA: 0,
    VALORIRRF: 0,
    RENDALIQUIDA: 0,
    loading: false,
    mes: 0
  };

  cardHeight = null

  componentDidMount() {
    this.setState({loading: true})
    const token = localStorage.getItem('token')

    if (!token) {
      return;
    }

    Api.get("investidor/app/valores-mes-referencia", {
      params: {
          NUMDOCUMENTO: this.props.user.login
      }
    }).then(({data}) => {
      if (data.status === "error") {
        this.props.showMessage({message: "Erro não esperado", open: true});
        this.setState({loading: false})
      } else {
        this.setState({...data.data, loading: false});
      }
    })
  }

  setMonthReference = (mes) => {
    this.setState({
      mes
    })
  }

  render() {
    const { VALORBRUTO, VALORFUNDORESERVA, VALORIRRF, RENDALIQUIDA, loading } = this.state;
    return (
      <div className="content">
        <Row>
          <Col lg="6">
            <CardHeader
              style={{ backgroundColor: 'inherit', paddingBottom: 0 }}
            >
              <CardTitle
                tag="h3"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  height: "100%"
                }}
              >
                Rendimentos
              </CardTitle>
            </CardHeader>
          </Col>
          <Col className="text-left" lg="6">
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                height: "100%"
              }}
            >
              <PeriodInfo numdoc={this.props.user.login} setMonthReference={this.setMonthReference} />
              <div
                style={{
                  borderRight: "1.5px solid #323344",
                  marginLeft: 10,
                  marginRight: 10,
                  width: 1,
                  height: "calc(100% - 40px)",
                  paddingTop: 20,
                  paddingBottom: 20
                }}
              />
              <UnitInfo numdoc={this.props.user.login} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="4">
            <CardUnit loading={loading} aluguel={VALORBRUTO} height={this.cardHeight} />
          </Col>
          <Col lg="4">
            <CardExpenses 
              loading={loading}
              found={VALORFUNDORESERVA}
              irrf={VALORIRRF}
              refFunction={r => this.cardHeight = r.offsetHeight}
            />
          </Col>
          <Col lg="4">
            <CardIncome loading={loading} income={RENDALIQUIDA} height={this.cardHeight} />
          </Col>
        </Row>
        
        <CardChart numdoc={this.props.user.login} mes={this.state.mes} />
        
        <Row>
          <Col lg="12">
            <CardTable
              title="Acumulado ano"
              numdoc={this.props.user.login}
              url="/investidor/app/lista-acumulado"
              detailUrl="/investidor/app/lista-detalhada-ano-acumulado"
              column={[
                {
                  title: "Período",
                  getCellValue: ({ANO}) => ANO
                },
                { title: "Unidades", name: "UNIDADES" },
                { title: "Fundo de reservas", name: "VALORFUNDORESERVA" },
                { title: "IRRF", name: "VALORIRRF" },
                { title: "Rend. líquida", name: "RENDALIQUIDA" }
              ]}
              accumType={1}
            />
          </Col>
        </Row>
        
        <Row>
          <Col lg="12">
            <CardTable
              title="Acumulado mês"
              numdoc={this.props.user.login}
              url="/investidor/app/lista-mes"
              detailUrl="/investidor/app/lista-detalhado-mes"
              detailPastUrl="/investidor/app/lista-detalhado-mes-ano-anterior"
              column={[
                {
                  title: "Período",
                  getCellValue: ({ mes, ANO }) => periodNameList[mes - 1] + "/" + ANO
                },
                { title: "Unidades", name: "UNIDADES" },
                {
                  title: "Fundo de reservas",
                  name: "VALORFUNDORESERVA"
                },
                { title: "IRRF", name: "VALORIRRF" },
                { title: "Rend. líquida", name: "RENDALIQUIDA" }
              ]}
              accumType={0}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({user}) => ({user});

export default connect(mapStateToProps, { showMessage })(Home);
