import React, { Component } from "react";
import classNames from "classnames";
import {
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  CardHeader,
  CardTitle
} from "reactstrap";

import CardTable from "./CardTable";

import Api from "../../api";
import {Cancel, CancelToken} from "axios";
import { connect } from "react-redux";
import { showMessage } from "../../actions";

let cancel;

const TabLink = ({ active, title, onClick }) => {
  return (
    <NavItem style={{ cursor: "pointer", border: "none !important" }}>
      <NavLink className={classNames({ active })} onClick={onClick}>
        <h6 style={{ color: active ? "#ffffffe0" : "#1f8ef1" }}>{title}</h6>
      </NavLink>
    </NavItem>
  );
};

class Reports extends Component {
  state = {
    activeTab: 0,
    reports: [],
    loading: true
  };

  componentDidMount() {
    this.loadReports();
  }

  handleChangeTab = activeTab => () => {
    this.setState({ activeTab }, this.loadReports)
  }

  getReportType = () => {
    switch (this.state.activeTab) {
      case 0:
        return "Aluguel mensal";
      case 1:
        return "Demonstrativos mensais";
      case 2:
        return "Informes de rendimento";
      case 3:
        return "Documentos legais";
      case 4:
        return "Assembléias";
      case 5:
        return "Condominio";
      default:
        return "N/A";
    }
  }

  loadReports = async () => {
    this.setState({loading: true});
    try {
      if (!!cancel) {
        cancel();
        cancel = null;
        await this.loadReports();
        return false;
      }
      
      const {data} = await Api.get(`/investidor/files-user?filetype=${this.getReportType()}`, {cancelToken: new CancelToken(function executor(c) 
        {
          cancel = c;
        }
      )});

      if (data.data !== false) {
        this.setState({reports: data.data, loading: false});
      } else {
        this.setState({loading: false});
      }
    } catch(e) {
      if (!(e instanceof Cancel)) {
        this.props.showMessage({message: "Erro ao carregar os documentos", open: true});
        this.setState({loading: false});
      }
    }
  }

  render() {
    const { loading, activeTab, reports } = this.state;

    return (
      <div className="content">
        <Row>
          <Col lg="12">
            <CardHeader
              style={{ backgroundColor: "#1d1d2c", paddingBottom: 0 }}
            >
              <CardTitle
                tag="h3"
                style={{
                  display: "flex",
                  justifyContent: "space-between"
                }}
              >
                Relatórios
              </CardTitle>
            </CardHeader>
          </Col>
        </Row>
        <Nav tabs>
          <TabLink
            active={activeTab === 0}
            title="Rendimento Mensal por Hotel"
            onClick={this.handleChangeTab(0)}
          />
          <TabLink
            active={activeTab === 1}
            title="DRE Sintéticopor Hotel"
            onClick={this.handleChangeTab(1)}
          />
          <TabLink
            active={activeTab === 2}
            title="IRRF Anual"

            onClick={this.handleChangeTab(2)}
          />
          <TabLink
            active={activeTab === 3}
            title="Documentos legais"

            onClick={this.handleChangeTab(3)}
          />
          <TabLink
            active={activeTab === 4}
            title="Assembleias"

            onClick={this.handleChangeTab(4)}
          />
          <TabLink
            active={activeTab === 5}
            title="Condominio"

            onClick={this.handleChangeTab(5)}
          />
        </Nav>
        <Row>
          <Col lg="12">
            <CardTable title="" reports={reports} loading={loading} />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({toast}) => ({toast});

export default connect(mapStateToProps, { showMessage })(Reports);
