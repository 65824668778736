import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, FormGroup, Input } from "reactstrap";

import { periods } from "data";
import { FaArrowLeft } from "react-icons/fa";

import CardChart from "./CardChart";
import CardData from "./CardData";

import Loading from "components/Loading";
import Api from "../../api";

import { showMessage } from "../../actions";

class HotelData extends Component {
  state = {
    loading: true, 

    name: "",
    month: 0,
    year: 0,
    
    actual: {
        TAXAOCUPACAO: [],
        DIARIAMEDIA: [],
        REVPAR: [],
        MARGEMRESULTADOOPERACIONAL: [],
        RECEITABRUTA: [],
        RESULTADOOPERACIONAL: [],
        ALUGUELUNIDADES: []
      },
    previous: {
        TAXAOCUPACAO: [],
        DIARIAMEDIA: [],
        REVPAR: [],
        MARGEMRESULTADOOPERACIONAL: [],
        RECEITABRUTA: [],
        RESULTADOOPERACIONAL: [],
        ALUGUELUNIDADES: []
      },
    
    actualDataset: {},
    previousDataset: {},

    hotelData: {}
  };

  componentDidMount() {
    const id = this.props.match.params.id;

    this.setState({ loading: true, hotelData: this.props.location.state.item });
    this.loadData(id);
  }

  loadData = async id => {
    try {
      const res = await Api.get("investidor/app/mes-referencia", {
        params: {
          NUMDOCUMENTO: this.props.login
        }
      })

      if (res.data.status === "error") {
        this.props.showMessage({message: res.data.data, open: true});
        this.setState({ loading: false });
        return false;
      }

      const { data } = await Api.get("investidor/app/hoteis-dados-adicionais", {
        params: {
          NUMDOCUMENTO: this.props.login,
          ANO: res.data.data.ANO,
          MES: res.data.data.MES,
          IDHOTEL: id
        }
      });

      const { data: previousData } = await Api.get("investidor/app/hoteis-dados-adicionais", {
        params: {
          NUMDOCUMENTO: this.props.login,
          ANO: res.data.data.ANO - 1,
          IDHOTEL: id
        }
      });

      if (data.status === "error") {
        this.props.showMessage({message: data.data, open: true});
        return false
      }

      // const allData = [...previousData.data, ...data.data];

      const MES = parseInt(res.data.data.MES, 10);
      const ANO = parseInt(res.data.data.ANO, 10);

      let TAXAOCUPACAO = [];
      let DIARIAMEDIA = [];
      let REVPAR = [];
      let MARGEMRESULTADOOPERACIONAL = [];
      let RECEITABRUTA = [];
      let RESULTADOOPERACIONAL = [];
      let ALUGUELUNIDADES = [];

      let actualDataset = {
        ocupacao: data.data.filter(item => item.LABEL === "TAXAOCUPACAO").map(item => item.VALOR),
        diariaMedia: data.data.filter(item => item.LABEL === "DIARIAMEDIA").map(item => item.VALOR),
        revpar: data.data.filter(item => item.LABEL === "REVPAR").map(item => item.VALOR)
      };

      let previousDataset = {
        ocupacao: previousData.data.filter(item => item.LABEL === "TAXAOCUPACAO").map(item => item.VALOR),
        diariaMedia: previousData.data.filter(item => item.LABEL === "DIARIAMEDIA").map(item => item.VALOR),
        revpar: previousData.data.filter(item => item.LABEL === "REVPAR").map(item => item.VALOR)
      };

      for (const item of data.data) {
        switch(item.LABEL) {
          case "TAXAOCUPACAO":
            TAXAOCUPACAO = [...TAXAOCUPACAO, item]
            break;
          case "DIARIAMEDIA":
            DIARIAMEDIA = [...DIARIAMEDIA, item]
            break;
          case "REVPAR":
            REVPAR = [...REVPAR, item]
            break;
          case "MARGEMRESULTADOOPERACIONAL":
            MARGEMRESULTADOOPERACIONAL = [...MARGEMRESULTADOOPERACIONAL, item]
            break;
          case "RECEITABRUTA":
            RECEITABRUTA = [...RECEITABRUTA, item]
            break;
          case "RESULTADOOPERACIONAL":
            RESULTADOOPERACIONAL = [...RESULTADOOPERACIONAL, item]
            break;
          case "ALUGUELUNIDADES":
            ALUGUELUNIDADES = [...ALUGUELUNIDADES, item]
            break;
          default:
            break;
        }
      }

      let actual = {
        TAXAOCUPACAO,
        DIARIAMEDIA,
        REVPAR,
        MARGEMRESULTADOOPERACIONAL,
        RECEITABRUTA,
        RESULTADOOPERACIONAL,
        ALUGUELUNIDADES
      }

      TAXAOCUPACAO = DIARIAMEDIA = REVPAR = MARGEMRESULTADOOPERACIONAL = RECEITABRUTA = RESULTADOOPERACIONAL = ALUGUELUNIDADES = [];

      for (const item of previousData.data) {
        switch(item.LABEL) {
          case "TAXAOCUPACAO":
            TAXAOCUPACAO = [...TAXAOCUPACAO, item]
            break;
          case "DIARIAMEDIA":
            DIARIAMEDIA = [...DIARIAMEDIA, item]
            break;
          case "REVPAR":
            REVPAR = [...REVPAR, item]
            break;
          case "MARGEMRESULTADOOPERACIONAL":
            MARGEMRESULTADOOPERACIONAL = [...MARGEMRESULTADOOPERACIONAL, item]
            break;
          case "RECEITABRUTA":
            RECEITABRUTA = [...RECEITABRUTA, item]
            break;
          case "RESULTADOOPERACIONAL":
            RESULTADOOPERACIONAL = [...RESULTADOOPERACIONAL, item]
            break;
          case "ALUGUELUNIDADES":
            ALUGUELUNIDADES = [...ALUGUELUNIDADES, item]
            break;
          default:
            break;
        }
      }

      let previous = {
        TAXAOCUPACAO,
        DIARIAMEDIA,
        REVPAR,
        MARGEMRESULTADOOPERACIONAL,
        RECEITABRUTA,
        RESULTADOOPERACIONAL,
        ALUGUELUNIDADES
      }

      this.setState({
        actual,
        previous,
        year: ANO,
        month: MES - 1,
        actualDataset,
        previousDataset,
        loading: false
      });
    } catch (e) {
      this.setState({loading: false});
      console.error(e)
    }
  }

  handleChange = value => {
    this.setState({month: value});
  };

  render() {
    const {
      loading,
      hotelData,
      year,
      month,
      actual,
      previous,
      actualDataset,
      previousDataset
    } = this.state;

    if (loading) {
      return <div className="content">
        <Loading />
      </div>
    }

    return (
      <div className="content">
        <Row style={{marginBottom: 20}}>
          <Col lg="12">
            <div style={{ display: "flex", alignItems: "center" }}>
              <FaArrowLeft
                size={25}
                color="#FFF"
                onClick={this.props.history.goBack}
              />
              <h4 style={{ marginLeft: 10, marginBottom: 0 }}>{hotelData.HOTEL}</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg="12">
            <CardChart
              actual={actualDataset}
              previous={previousDataset}
              year={year}
              onClickLabel={this.handleChange}
            />
          </Col>
        </Row>
        <Row>
          <Col lg="2">
            <FormGroup>
              <Input
                type="select"
                name="select"
                id="exampleSelect"
                value={month}
                onChange={e => {
                  const value = parseInt(e.target.value, 10);

                  this.handleChange(value);
                }}
              >
                {periods.map((period, index) => (
                  <option style={{ color: "#303132" }} value={index}>
                    {period}
                  </option>
                ))}
              </Input>
            </FormGroup>
            </Col>
        </Row>
        <Row>
          <CardData
            title="Ocupação"
            actual={actual.TAXAOCUPACAO}
            previous={previous.TAXAOCUPACAO}
            previousLabel={year - 1}
            actualLabel={year}
            month={month}
            type="%"
          />
          <CardData
            title="Diária Média R$"
            actual={actual.DIARIAMEDIA}
            previous={previous.DIARIAMEDIA}
            previousLabel={year - 1}
            actualLabel={year}
            month={month}
            type="R$"
          />
          <CardData
            title="RevPar R$"
            actual={actual.REVPAR}
            previous={previous.REVPAR}
            previousLabel={year - 1}
            actualLabel={year}
            month={month}
            type="R$"
          />
          <CardData
            title="Receita Bruta R$"
            actual={actual.RECEITABRUTA}
            previous={previous.RECEITABRUTA}
            previousLabel={year - 1}
            actualLabel={year}
            month={month}
            type="R$"
            acumType={2}
          />
          <CardData
            title="Resultado Operacional R$"
            actual={actual.RESULTADOOPERACIONAL}
            previous={previous.RESULTADOOPERACIONAL}
            previousLabel={year - 1}
            actualLabel={year}
            month={month}
            type="R$"
            acumType={2}
          />
          <CardData
            title="Margem Resultado Operacional"
            actual={actual.MARGEMRESULTADOOPERACIONAL}
            previous={previous.MARGEMRESULTADOOPERACIONAL}
            previousLabel={year - 1}
            actualLabel={year}
            month={month}
            type="%"
          />
          <CardData
            title="Resultado por Unidade R$"
            actual={actual.ALUGUELUNIDADES}
            previous={previous.ALUGUELUNIDADES}
            previousLabel={year - 1}
            actualLabel={year}
            month={month}
            type="R$"
            acumType={2}
          />
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({user}) => ({login: user.login});

export default connect(mapStateToProps, { showMessage })(HotelData);
