import React from "react";
import { connect } from "react-redux";

// import { Container } from './styles';
import { login, showMessage } from "actions";
import {
  Row,
  Col,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import { useState } from "react";
import { FaKey, FaUser } from "react-icons/fa";
import Api from "../../api";
import { useConfigContext } from "../../contexts/config-context";

const Login = ({ login, history, showMessage, ...rest }) => {
  const [username, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const { logo } = useConfigContext();
  const handleLogin = async () => {
    try {
      if (!username || !password) {
        showMessage({message: "Login ou a senha não preenchidos", open: true});
        return false;
      }

      const { data } = await Api.post("investidor/auth/login", {
        login: username,
        password
      });

      if (data.status === "success") {
        localStorage.setItem("token", data.data.token);

        login({
          login: username,
          ...data.data
        });
        history.push("/admin/dashboard");

        return true; 
      } else {
        showMessage({message: "Falha ao autenticar", open: true});
      }
    } catch (e) {
      console.error(e)
      showMessage({message: "Erro ao entrar", open: true});
    }
  };

  return (
    <div
      className="content"
      style={{
        height: "100%",
        display: "flex",
        justifyContent: "space-evenly",
        flexDirection: "column",
        backgroundImage: `url(${require("../../assets/img/bg_login.png")})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        overflow: "hidden",
        zIndex: -2
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center"
        }}
      >
        <img src={logo} alt="" />
      </div>
      <Row
        style={{ justifyContent: "center", alignItems: "center", zIndex: 2 }}
      >
        <Col lg="3">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText
                style={{ backgroundColor: "#000", padding: 0, paddingLeft: 10 }}
              >
                <FaUser />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              value={username}
              onChange={e => setLogin(e.target.value)}
              style={{ backgroundColor: "#000" }}
              placeholder="CPF ou CNPJ"
            />
          </InputGroup>

          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText
                style={{ backgroundColor: "#000", padding: 0, paddingLeft: 10 }}
              >
                <FaKey />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              value={password}
              onChange={e => setPassword(e.target.value)}
              style={{ backgroundColor: "#000" }}
              placeholder="Senha"
              type="password"
            />
          </InputGroup>
          <h6
            onClick={() => {
              history.push("/forgotPassword")
            }}
            style={{ textAlign: "right", cursor: "pointer" }}
          >
            Esqueceu a senha?
          </h6>
          <Button color="info" style={{ width: "100%" }} onClick={handleLogin}>
            ENTRAR
          </Button>
        </Col>
      </Row>
      <Row>
      </Row>
      <ul class="squares"></ul>
    </div>
  );
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, { login, showMessage })(Login);
