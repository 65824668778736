const initialState = {
  name: "",
  username: "",
};

const defaultAction = {
  type: "",
  payload: initialState
};

export default (state = initialState, action = defaultAction) => {
  switch (action.type) {
    case "LOGIN":
      return action.payload;
    case "LOGOUT":
      return initialState;
    case "CHANGE_DATA":
      return {...state, ...action.payload};
    default:
      return state;
  }
};
