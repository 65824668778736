import React from 'react'
import { useConfigContext } from '../../contexts/config-context'

function Logo({maxWidth = '185px', maxHeight = '36px'}) {
    const { logo } = useConfigContext()
    return (
        <img src={logo} alt='logo' style={{maxWidth, maxHeight}}/>
    )
}

export default Logo