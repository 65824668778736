/*!

=========================================================
* Black Dashboard React v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import AdminLayout from "layouts/Admin/Admin.jsx";
import RTLLayout from "layouts/RTL/RTL.jsx";
import { Provider, connect } from "react-redux";
import { PersistGate } from "redux-persist/es/integration/react";
import {store, persistor} from "./store";

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";
import "assets/css/open-iconic-bootstrap.min.css";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";

import Login from "pages/login";
import ForgorPassword from "./pages/login/forgotPassword";
import Terms from "pages/terms";
import { Alert } from "reactstrap";

import { hideMessage } from "./actions";
import { ConfigContextProvider } from "./contexts/config-context";

const hist = createBrowserHistory();

const Routes = ({toast, hideMessage}) => {
  useEffect(() => {
    setTimeout(() => {
      hideMessage();
    }, [3000]);
  }, [toast.open]);

  return (
    <>
      <ConfigContextProvider>
        <Alert isOpen={!!toast.open} toggle={hideMessage} color="danger">
          {toast.message}
        </Alert>
        <Router history={hist}>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/forgotPassword" component={ForgorPassword} />
            <Route path="/terms" component={Terms} />
            <Route path="/admin" render={props => <AdminLayout {...props} />} />
            <Route path="/rtl" render={props => <RTLLayout {...props} />} />
            <Redirect from="/" to="/admin/dashboard" />
          </Switch>
        </Router>
      </ConfigContextProvider>
    </>
  )
}

const mapStateToProps = ({toast}) => ({toast});

const ProvierRouter = connect(mapStateToProps, { hideMessage })(Routes);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ProvierRouter />      
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
