import React, { createContext, useContext, useEffect, useState } from 'react';
import lodash from 'lodash';
import Api from '../api';
const ConfigContext = createContext();


const ConfigContextProvider = ({ children }) => {
    const [logo, setLogo] = useState('');
    const [theme, setTheme] = useState({gradient: {main: {start:'', end: '', text: ''}, alt: {start: '', end: '', text: ''}}});
    const [sidebarColor, setSidebarColor] = useState([]);
    const [textColor, setTextColor] = useState('');
    useEffect(() => {
      Api.get('investidor/config-portal').then(({data}) => {
        const {logo, theme} = data.data;
        if(theme) {
          setTheme(theme);
          const currentSidebar = localStorage.getItem('currentSidebar');
          const current = theme.gradient[currentSidebar ? currentSidebar : 'main'];
          setSidebarColor([current.start, current.end])
          setTextColor(current.text);
        }
        if(logo) {
          setLogo(logo); 
        }
       
        
      });
    },[]);
    const changeSidebarColor = () => {
      const currentSidebar = localStorage.getItem('currentSidebar');
      if((currentSidebar ? currentSidebar : 'main' ) === 'main') {
        const alt = theme.gradient.alt;
        setSidebarColor([alt.start, alt.end]);
        setTextColor(alt.text);
        localStorage.setItem('currentSidebar', 'alt');
        return;
      }
      const main = theme.gradient.main;
      setSidebarColor([main.start, main.end])
      setTextColor(main.text);
      localStorage.setItem('currentSidebar', 'main');
      return;
    }
    return (
      <ConfigContext.Provider value={{ logo, setLogo, theme, setTheme, changeSidebarColor, sidebarColor, textColor}}>
        {children}
      </ConfigContext.Provider>
    );
  };

const useConfigContext = () => {

    const context  = useContext(ConfigContext);

    if(lodash.isEmpty(context)) throw new Error('Context not found')

    return context
}


export {ConfigContext, ConfigContextProvider, useConfigContext}