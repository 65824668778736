import axios from 'axios';

export const getCancelToken = () => {
  const CancelToken = axios.CancelToken;

  return CancelToken.source();
};

const Api = axios.create({
  baseURL: process.env.REACT_APP_ENDPOINT,
});
const publicUrls = {
  "investidor/auth/login": true,
  "investidor/auth/recover": true,
  "investidor/auth/recovery": true,
  "investidor/config-portal": true
}
Api.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (!publicUrls[config.url]) {
      config.headers.Authorization = `Bearer ${token}`;
  }
  config.headers['Grupo-Empresa'] = process.env.REACT_APP_GRUPO_EMPRESA;
  return config;
});

export default Api;
